import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useGameContext } from "./GameContext";
import { EnterNameDialog } from "./EnterNameDialog";

export function Banner() {
  let gameContext = useGameContext();

  let [enterNameDialogOpen, setEnterNameDialogOpen] = React.useState(false);
  function changeName() {
    setEnterNameDialogOpen(true);
  }

  return <AppBar className={"banner"} color={"primary"}>
    <Toolbar disableGutters>
      <Stack flexDirection={"row"} justifyContent={"flex-start"} flexGrow={1}>
        <Box key={"left-logo"} display={"flex"} alignItems={"center"} gap={"1em"} margin={"0.5em"}>
          <a href={`${process.env.PUBLIC_URL}/`}>
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
            }}
            alt="Word bomb icon"
            src="assets/icon.png"
          />
          </a>
          <Typography variant="h3" component="div"
                      sx={{
                        flexGrow: 1,
                      }}>
            word bomb!
          </Typography>
        </Box>
        <Box key={"right-tools"} marginLeft={"auto"} display={"flex"} alignItems={"center"} gap={"1em"} marginRight={"0.5em"} marginTop={"0.5em"}>
          <Typography onClick={()=>changeName()} style={{cursor: "pointer"}}>
            {gameContext.name}
          </Typography>
          <EnterNameDialog open={enterNameDialogOpen} handleClose={()=>setEnterNameDialogOpen(false)} />
        </Box>
      </Stack>
    </Toolbar>
  </AppBar>
}

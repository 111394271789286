import './Explosion.css';
import React, { createRef, RefObject, useEffect, useMemo } from "react";
import gsap from "gsap";

export interface ExplosionProps {started: boolean, onComplete: ()=>void}
export function Explosion({ started, onComplete }: ExplosionProps) {
  let explosionSvgContainerRef = useMemo(()=>createRef<HTMLDivElement>(), []);
  const stars = Array.from({ length: 15 }).map((_, index) => ({
    rotation: Math.random() * 360,
    xOffset: Math.random() * 20 - 10, // Offset range -10 to 10
    yOffset: Math.random() * 20 - 10,
    color: `hsl(${20 + index * 3}, 100%, 50%)` // Gradient from red to orange
  }));

  useEffect(() => {
    if (explosionSvgContainerRef && onComplete && stars){
      if (started) {
        start(stars, explosionSvgContainerRef, onComplete);
      } else {
        const explosion = explosionSvgContainerRef.current;
        gsap.set(explosion, { display: "none" });
      }
    }
  }, [started, explosionSvgContainerRef, onComplete, stars]);

  return <div ref={explosionSvgContainerRef} className={"explosion"} style={{display: "none"}}>
      {stars.map((star, index) => (
        <svg key={index} className={"star"} viewBox="0 0 100 100">
          <path d="M50,0 L60,40 L100,40 L70,60 L80,100 L50,70 L20,100 L30,60 L0,40 L40,40 L50,0 Z" style={{ fill: star.color }}/>
        </svg>
      ))}
  </div>;
}

const start = (stars: {rotation: number, xOffset: number, yOffset: number, color: string}[], explosionSvgContainerRef: RefObject<HTMLDivElement>, onComplete?: ()=>void) => {
  const containerDiv = explosionSvgContainerRef.current;
  if (!containerDiv) {
    return;
  }

  gsap.set(containerDiv, { display: "unset" });

  stars.forEach((star, index) => {
    const starEl = containerDiv.children[index];
    gsap.fromTo(starEl, {
      scale: 0,
      x: star.xOffset,
      y: star.yOffset,
      rotation: star.rotation,
      transformOrigin: 'center'
    }, {
      scale: 1,
      duration: 1,
      ease: 'power1.out',
      delay: index * 0.05 // Stagger the animation
    });
  });

  // Create a tween
  gsap.to(containerDiv, {
    // Animate scale to simulate 'radius' expansion
    scale: 1,
    // Loop the animation
    repeat: 0,
    // Wait 1sec before repeating
    repeatDelay: 1,
    // Make the animation last 1 second
    duration: 1,
    onComplete: () => {
      if (onComplete) {
        onComplete();
      }
    }
  });
}

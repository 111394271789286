import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGameContext } from "./GameContext";

export function EnterCode() {
  let navigate = useNavigate();
  let { join } = useGameContext();

  let [code, setCode] = useState<string>('');

  let [noSuchRoom, setNoSuchRoom] = useState(false);

  function joinGame() {
    if (code.length === 4) {
      join(code, false, () => navigate(`/game/${code}`), () => setNoSuchRoom(true));
    }
  }

  useEffect(() => {
    setNoSuchRoom(false);
  }, [code]);

  return <Box
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
    justifyContent={"center"}
    height={"100%"}
    width={"100%"}
  >
    <Stack spacing={"1em"}>
      <Typography variant="h5">
        join a room!
      </Typography>
      <Box>
        <Box display="flex" padding={1}>
          <TextField
            autoFocus
            label="Enter Code:"
            required
            type="text"
            value={code}
            inputProps={{
              maxLength: 4,
              minLength: 4,
            }}
            onChange={event => setCode(event.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                joinGame();
              }
            }}
          />
          <Button
            disabled={code.length !== 4}
            onClick={() => joinGame()}
          >Join</Button>
          {noSuchRoom && <Typography color="error">no such room</Typography>}
        </Box>
      </Box>
    </Stack>
  </Box>;
}

import { PaletteOptions, ThemeOptions} from "@mui/material";

const palette: PaletteOptions = {
  primary: {
    main: '#3D1970',
    light: '#FF53A5',
    contrastText: '#FEF9ED',
  },
  secondary: {
    main: '#43668F',
  },
  error: {
    main: '#F02880',
  },
}
export const lightTheme: ThemeOptions = {
  palette: {...palette, mode: 'light'},
}
export const darkTheme: ThemeOptions = {
  palette: {...palette, mode: 'dark'},
}



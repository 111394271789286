import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import {
    CombinationMessage,
    CreatedMessage, DoneMessage,
    GameHistoryItem,
    JoinedMessage, Member,
    Message,
    ScoreboardMessage,
    TickMessage,
    UpdateMembersMessage,
    IdentifyMessage,
    IdMessage
} from "../../data/message";

export enum TinyMessageType { //update the matching one on the server when changed
    playerKeys = "k"
}

function isIdMessage(message: Message): message is IdMessage {
    return message.type === 'id';
}
function isCreatedMessage(message: Message): message is CreatedMessage {
    return message.type === 'created';
}
function isJoinedMessage(message: Message): message is JoinedMessage {
    return message.type === 'joined';
}
function isDoneMessage(message: Message): message is DoneMessage {
    return message.type === 'done';
}
function isUpdateMembersMessage(message: Message): message is UpdateMembersMessage {
    return message.type === 'updateMembers';
}
function isCombinationMessage(message: Message): message is CombinationMessage {
    return message.type === 'combination';
}
function isTickMessage(message: Message): message is TickMessage {
    return message.type === 'tick';
}
function isScoreboardMessage(message: Message): message is ScoreboardMessage {
    return message.type === 'scoreboard';
}

export interface GameContextValue {
    id: string;
    identified: boolean;
    connected: boolean;
    name: string;
    changeName: (name: string) => void;
    roomCode?: string;
    scoreboard: { [memberId: string]: number };
    typedLetters: { [memberId: string]: string };
    createRoom: (created: (code: string) => void) => void;
    join: (roomCode: string, createIfMissing: boolean, joined: () => void, noSuchRoom: () => void) => void;
    leave: () => void;

    setGameUpdateCallbacks: (
        startingRound: () => void,
        bombStart: (combination: string, totalTime: number) => void,
        bombTick: (remaining: number) => void,
        explode: () => void,
        roundDone: (history: GameHistoryItem[]) => void
    ) => void;
    start: () => void;

    answer: (answer: string, valid: () => void, invalid: () => void) => void;
    typing: (letters: string) => void;

    roomMembers: Member[];
}

const storedId = localStorage.getItem("id") || "";
const storedName = localStorage.getItem("name") || "anon";

const GameContext = createContext<GameContextValue>({
    id: "",
    identified: false,
    connected: false,
    name: "",
    changeName: () => { },
    createRoom: () => { },
    join: () => { },
    leave: () => { },
    setGameUpdateCallbacks: () => { },
    start: () => { },
    answer: () => { },
    typing: () => { },
    roomMembers: [],
    scoreboard: {},
    typedLetters: {},
});

export function GameContextProvider({ children }: {
    children: ReactNode,
}) {
    let [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    let [connected, setConnected] = useState<boolean>(false);

    let [id, setId] = useState<string>(storedId);
    let [identified, setIdentified] = useState<boolean>(false);
    let [name, setName] = useState(storedName);
    let [roomCode, setRoomCode] = useState<string | undefined>(undefined);
    let [roomMembers, setRoomMembers] = useState<Member[]>([]);
    let [scoreboard, setScoreboard] = useState<{ [member: string]: number }>({});
    let [typedLetters, setTypedLetters] = useState<{ [member: string]: string }>({});

    let [createdCallback, setCreatedCallback] = useState<((code: string) => void) | null>(null);
    let [joinedCallback, setJoinedCallback] = useState<(() => void) | null>(null);
    let [noSuchRoomCallback, setNoSuchRoomCallback] = useState<(() => void) | null>(null);

    let [startingRoundCallback, setStartingRoundCallback] = useState<(() => void) | null>(null);
    let [bombStartCallback, setBombStartCallback] = useState<((combination: string, totalTime: number) => void) | null>(null);
    let [bombTickCallback, setBombTickCallback] = useState<((remaining: number) => void) | null>(null);
    let [explodeCallback, setExplodeCallback] = useState<(() => void) | null>(null);
    let [roundDoneCallback, setRoundDoneCallback] = useState<((history: GameHistoryItem[]) => void) | null>(null);

    let [validCallback, setValidCallback] = useState<(() => void) | null>(null);
    let [invalidCallback, setInvalidCallback] = useState<(() => void) | null>(null);

    useEffect(() => {
        let url = process.env.REACT_APP_WS_URL!;
        if (url !== 'test') {
            let ws = new WebSocket(url);
            setWebSocket(ws);

            ws.onopen = () => {
                setConnected(true);
            };
            ws.onclose = () => {
                setConnected(false);
            };
        }
    }, []);

    let tinyMessageCallback = useCallback((messageType: TinyMessageType, message: string) => {
        switch (messageType) {
            case TinyMessageType.playerKeys: {
                let playerIndex: number | undefined;
                let letters: string | undefined;

                let match = message.match(/^\d+/);
                if (match) {
                    playerIndex = parseInt(match[0]);
                    letters = message.substring(match[0].length);
                } else {
                    console.error("No player index in tiny message", message);
                    return;
                }
                let roomMember = roomMembers[playerIndex];
                if (roomMember) {
                    let playerId = roomMember.id;
                    setTypedLetters({
                        ...typedLetters,
                        [playerId]: letters,
                    });
                } else {
                    console.error("No room member for player index", playerIndex, roomMembers);
                }
                break;
            }
            default:
                console.error("Unknown tiny message type", messageType, message);
        }
    }, [roomMembers, typedLetters]);

    let messageCallback = useCallback((message: Message) => {
        switch (message.type) {
            case "id": {
                if (isIdMessage(message)) {
                    if (!id) {
                        setId(message.id);
                    }
                    let identifyMessage: IdentifyMessage = {
                        type: "identify",
                        id: id || message.id,
                        name,
                    };
                    webSocket!.send(JSON.stringify(identifyMessage));
                    setIdentified(true);
                }
                break;
            }
            case "created": {
                if (isCreatedMessage(message)) {
                    setRoomCode(message.code);
                    if (createdCallback) {
                        createdCallback(message.code);
                        setCreatedCallback(null);
                    }
                }
                break;
            }
            case "joined": {
                if (isJoinedMessage(message)) {
                    setRoomCode(message.code);
                    if (joinedCallback) {
                        joinedCallback();
                        setJoinedCallback(null);
                    }
                }
                break;
            }
            case "noSuchRoom": {
                if (noSuchRoomCallback) {
                    noSuchRoomCallback();
                    setNoSuchRoomCallback(null);
                }
                break;
            }
            case "updateMembers": {
                if (isUpdateMembersMessage(message)) {
                    setRoomMembers(message.members);
                }
                break;
            }
            case "starting": {
                if (startingRoundCallback) {
                    startingRoundCallback();
                }
                break;
            }
            case "combination": {
                if (bombStartCallback && isCombinationMessage(message)) {
                    setTypedLetters({});
                    bombStartCallback(message.combination, message.timeAllowed);
                }
                break;
            }
            case "tick": {
                if (bombTickCallback && isTickMessage(message)) {
                    bombTickCallback(message.timeLeft);
                }
                break;
            }
            case "explode": {
                if (explodeCallback) {
                    explodeCallback();
                }
                break;
            }
            case "done": {
                if (roundDoneCallback && isDoneMessage(message)) {
                    roundDoneCallback(message.history);
                    setTypedLetters({});
                }
                break;
            }
            case "valid": {
                if (validCallback) {
                    validCallback();
                    setValidCallback(null);
                }
                break;
            }
            case "invalid": {
                if (invalidCallback) {
                    invalidCallback();
                    setInvalidCallback(null);
                }
                break;
            }
            case "scoreboard": {
                if (isScoreboardMessage(message)) {
                    setScoreboard(message.scores);
                }
                break;
            }
            default:
                console.error("Unknown message type", message);
        }
    }, [id, name, webSocket, createdCallback, joinedCallback, noSuchRoomCallback, startingRoundCallback, bombStartCallback, bombTickCallback, explodeCallback, roundDoneCallback, validCallback, invalidCallback]);

    if (webSocket) {
        webSocket.onmessage = (event) => {
            if (event.data[0] === "{" || event.data[0] === "[") {
                let message = JSON.parse(event.data) as Message;
                messageCallback(message);
            } else {
                let messageType = event.data[0] as TinyMessageType;
                let message = event.data.substring(1);
                tinyMessageCallback(messageType, message);
            }
        };
    }

    useEffect(() => {
        localStorage.setItem("name", name);
    }, [name]);

    useEffect(() => {
        localStorage.setItem("id", id);
    }, [id]);

    return <GameContext.Provider
        value={{
            id,
            identified,
            connected,
            name,
            changeName: useCallback((name) => {
                setName(name);
                let identifyMessage: IdentifyMessage = {
                    type: "identify",
                    id,
                    name,
                };
                webSocket!.send(JSON.stringify(identifyMessage));
            }, [id, webSocket]),
            roomCode,
            createRoom: useCallback((created) => {
                if (connected) {
                    setCreatedCallback((previousCallback: null | ((code: string) => void)) => created);
                    webSocket!.send(JSON.stringify({
                        type: "create",
                    }));
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            join: useCallback((roomCode, createIfMissing, joined, noSuchRoom) => {
                if (connected) {
                    setJoinedCallback(previousCallback => joined);
                    setNoSuchRoomCallback(previousCallback => noSuchRoom);
                    webSocket!.send(JSON.stringify({
                        type: "join",
                        createIfMissing,
                        code: roomCode.toUpperCase(),
                    }));
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            leave: useCallback(() => {
                if (connected) {
                    webSocket!.send(JSON.stringify({
                        type: "leave",
                    }));
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            setGameUpdateCallbacks: useCallback((startingRound, bombStart, bombTick, explode, roundDone) => {
                setStartingRoundCallback(previousCallback => startingRound);
                setBombStartCallback((previousCallback: ((combination: string, totalTime: number) => void) | null) => bombStart);
                setBombTickCallback((previousCallback: ((remaining: number) => void) | null) => bombTick);
                setExplodeCallback(previousCallback => explode);
                setRoundDoneCallback((previousCallback: ((history: GameHistoryItem[]) => void) | null) => roundDone);
            }, []),
            start: useCallback(() => {
                if (connected) {
                    webSocket!.send(JSON.stringify({
                        type: "start",
                    }));
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            answer: useCallback((answer, valid, invalid) => {
                if (connected) {
                    setValidCallback(previousCallback => valid);
                    setInvalidCallback(previousCallback => invalid);
                    webSocket!.send(JSON.stringify({
                        type: "word",
                        word: answer,
                    }));
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            typing: useCallback((letters) => {
                if (connected) {
                    webSocket!.send(`${TinyMessageType.playerKeys}${letters}`);
                } else {
                    throw new Error("Not connected");
                }
            }, [connected, webSocket]),
            roomMembers,
            scoreboard,
            typedLetters,
        }}
    >
        {children}
    </GameContext.Provider>
}

export const useGameContext = () => useContext(GameContext);

import React, { createRef, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useGameContext } from "./GameContext";
import { Bomb } from "./Bomb";
import { GameHistoryItem } from "../../data/message";
import { playSound } from "./utils";

const successSound = new Audio(`${process.env.PUBLIC_URL}/assets/success.mp3`);
const failSound = new Audio(`${process.env.PUBLIC_URL}/assets/fail.mp3`);

export function Game() {
  let { code: codePathParam } = useParams();
  let { identified, connected, roomMembers, roomCode, scoreboard, typedLetters, join, leave, start, setGameUpdateCallbacks, answer, typing } = useGameContext();
  let [history, setHistory] = useState<GameHistoryItem[] | null>(null);

  useEffect(() => {
    if (!roomCode && codePathParam && connected && identified) {
      join(codePathParam, true, () => { }, () => { });
    }
    return () => {
      if (roomCode && connected) {
        leave();
      }
    }
  }, [identified, connected, roomCode, codePathParam, join, leave]);
  let [started, setStarted] = React.useState(false);
  let [gameInProgress, setGameInProgress] = React.useState(false);
  let [combination, setCombination] = React.useState("");
  let [totalSeconds, setTotalSeconds] = React.useState(15);
  let [remainingSeconds, setRemainingSeconds] = React.useState(15);
  let [exploded, setExploded] = React.useState(false);

  let [gotIt, setGotIt] = React.useState(false);

  let [word, setWord] = useState("");

  const wordFieldRef = useMemo(() => createRef<HTMLInputElement>(), []);

  useEffect(() => {
    function startingRound() {
      setStarted(true);
    }

    function bombStart(combination: string, totalTime: number) {
      setCombination(combination);
      setRemainingSeconds(totalTime);
      setTotalSeconds(totalTime);
      setGameInProgress(true);
      setExploded(false);
      setWord("");
      setStarted(true);
      setGotIt(false);

      if (wordFieldRef.current) {
        const inputElement = wordFieldRef.current;
        setTimeout(() => {
          inputElement.focus();
        }, 100);
      }
    };

    function bombTick(remaining: number) {
      setRemainingSeconds(remaining);
      setStarted(true);
      if (!gotIt) {
        setGameInProgress(true);
      }
    }
    function explode() {
      setRemainingSeconds(0);
      setGameInProgress(false);
      setStarted(true);
      setExploded(true);
      typing(word); // Update the server with the final word
    }
    function roundDone(history: GameHistoryItem[]) {
      setStarted(false);
      setCombination("");
      setExploded(false);
      setWord("");
      setHistory(history);
    }

    setGameUpdateCallbacks(
      startingRound,
      bombStart,
      bombTick,
      explode,
      roundDone
    );
  }, [setGameUpdateCallbacks, typing, word, wordFieldRef, gotIt]);

  return <Grid
    container
    direction={"row"}
    height={"100%"}
    width={"100%"}
  >
    <Grid item xs={4} padding={"2em"}>
      <Stack direction="column" spacing={2}>
        <Typography>
          Players in room (<a href={`${process.env.PUBLIC_URL}/#/game/${roomCode}`}>{roomCode}</a>)
        </Typography>
        {roomMembers?.map((member, index) =>
          <Card key={index} sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  (Score: {scoreboard?.[member.id] ?? 0})
                </Typography>
              </CardContent>
            </Box>
            <CardMedia
              key={"player-word-details-" + index}
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              {typedLetters?.[member.id] && <Typography variant="subtitle1" color="text.secondary" component="div">
                {gameInProgress ? "*".repeat(typedLetters?.[member.id].length) : typedLetters?.[member.id]}
              </Typography>}
            </CardMedia>
          </Card>
        )}
        {!started && history &&
          <Table key={"history-table"}>
            <TableHead>
              <TableCell>Combination</TableCell>
              {roomMembers.map((member, index) =>
                <TableCell key={index}>{member.name}</TableCell>
              )}
            </TableHead>
            <TableBody>
              {history.map((historyItem, index) =>
                <TableRow key={index}>
                  <TableCell>
                    <Tooltip title={historyItem.possibleWords.join(', ')}><Typography>{historyItem.combination}</Typography></Tooltip>
                    <Typography className="screen-reader-only">(e.g., {historyItem.possibleWords.join(', ')})</Typography>
                  </TableCell>
                  {roomMembers.map((member, index) => {
                    const answer = historyItem.answers[member.id];
                    if (answer) {
                      if (answer.answer) {
                        return <TableCell key={index}>{`${answer.answer} (${answer.score})`}</TableCell>
                      } else {
                        return <TableCell key={index}><Typography className="screen-reader-only">(failed)</Typography><Typography color="error">{answer.invalidAttempts.join(', ')}</Typography></TableCell>
                      }
                    } else {
                      return <TableCell key={index}>-</TableCell>
                    }
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
      </Stack>
    </Grid>
    <Grid item xs={8}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        {!started ?
          <Box>
            <Button onClick={() => start()}>
              start game
            </Button>
          </Box>
          :
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction={"row"}>
              <Typography variant={"h4"} aria-hidden>&nbsp;</Typography><Typography variant={"h4"} role="none">{gameInProgress && combination ? combination : ""}</Typography><Typography variant={"h4"} aria-hidden>&nbsp;</Typography>
            </Stack>
            <Bomb started={gameInProgress} seconds={totalSeconds} remaining={remainingSeconds} exploded={exploded} />
            <TextField
              key={"word-input-field"}
              inputRef={wordFieldRef}
              disabled={!gameInProgress}
              value={word}
              autoComplete={"off"}
              onChange={(event) => {
                let value = event.target.value;
                value = value.toLowerCase();
                value = value.replace(/[^a-z]/g, "");
                setWord(value);
                typing(value);
              }}
              autoFocus
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  answer(word, () => {
                    setGameInProgress(false);
                    playSound(successSound);
                    typing(word); // Update the server with the final word
                    setGotIt(true);
                  }, () => {
                    setWord("");
                    playSound(failSound);
                    typing("");
                  });
                }
              }}
              inputProps={{
                maxLength: 15, // Since the scrabble dictionary (which we use) only has words up to 15 characters
              }}
            />
          </Stack>
        }
      </Box>
    </Grid>
  </Grid>
}

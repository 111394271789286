import "./Bomb.css";
import React, { RefObject, createRef, useEffect, useMemo, useState } from "react";
import gsap from "gsap";
import { Explosion } from "./Explosion";
import { Box } from "@mui/material";
import { playSound } from "./utils";

//ref: https://gsap.com/resources/React/
//ref: https://codepen.io/Mamboleoo/pen/NWaogXW

interface BombProps { started: boolean, seconds: number, remaining: number, exploded: boolean }

const matchSound = new Audio(`${process.env.PUBLIC_URL}/assets/match.mp3`);
const fuseSound = new Audio(`${process.env.PUBLIC_URL}/assets/fuse.mp3`);
const explosionSound = new Audio(`${process.env.PUBLIC_URL}/assets/explosion.mp3`);

export function Bomb({ started, seconds, remaining, exploded }: BombProps) {
  let bombSvgRef = useMemo(() => createRef<SVGSVGElement>(), []);
  let [explosionStarted, setExplosionStarted] = useState(false);
  let [fuseAnimation, setFuseAnimation] = useState<FuseAnimation>();
  useEffect(() => {
    if (started) {
      setFuseAnimation(startFuse(bombSvgRef, seconds));
      playSound(matchSound);
      fuseSound.loop = true;
      playSound(fuseSound);
    }
  }, [started, seconds, bombSvgRef]);

  useEffect(() => {
    if (!started) {
      fuseSound.pause();
      fuseSound.currentTime = 0;
      fuseAnimation?.fuse.pause();
    }
  }, [started, fuseAnimation]);

  useEffect(() => {
    if (exploded) {
      playSound(explosionSound);
      setExplosionStarted(true)
    }
  }, [exploded]);

  useEffect(() => {
    return () => {
      fuseSound.pause();
      fuseSound.currentTime = 0;
    }
  }, []);

  return <Box className={"bomb"}>
          <Explosion started={explosionStarted} onComplete={()=>setExplosionStarted(false)} />
          <svg ref={bombSvgRef} className={"bombSvg"} viewBox="-10 -10 361 220">
            <path d="M96.5,43.2a8.21,8.21,0,0,0-11.24,3L78.79,57.42h0A72.21,72.21,0,1,0,131.59,89l7-12a8.21,8.21,0,0,0-3-11.24Z" fill="#000" className={"bomb"} />
            <path
              d="M260,26.88c-15.68-28.38,50.4-35.16,71.16-14s8.47,50.84-38.12,50.84S210,22.67,184.63,21.38s-71.3,34.28-71.3,34.28" transform="translate(0 0)"
              fill="none"
              stroke="#231f20"
              strokeWidth={2}
              strokeDasharray={377}
              strokeDashoffset={754}
              className="fuse"
            />
          </svg>
        </Box>;
}

interface FuseAnimation {
  fuse: gsap.core.Tween;
}

function startFuse(bombSvgRef: RefObject<SVGSVGElement>, seconds: number): FuseAnimation {
  const svg = bombSvgRef.current;
  const fuseSvg = svg?.querySelector('.fuse') as SVGPathElement;

  // Create an object that gsap can animate
  const val = { distance: 0 };
  // Create a tween
  let fuse = gsap.to(val, {
    // Animate from distance 0 to the total distance
    distance: fuseSvg?.getTotalLength(),
    // Loop the animation
    repeat: 0,
    // Wait 1sec before repeating
    repeatDelay: 1,
    // Make the animation lasts 5 seconds
    duration: seconds,
    // Function call on each frame of the animation
    onUpdate: () => {
      // Query a point at the new distance value
      const point = fuseSvg.getPointAtLength(val.distance);
      createParticle(point);
      fuseSvg.setAttribute('stroke-dashoffset', `${754 - val.distance}`);
    }
  });

  function createParticle(point: any) {
    // Create a new circle element
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    // Prepend the element to the SVG
    svg?.prepend(circle);
    // Set the coordinates of that circle
    circle.setAttribute('cx', point.x);
    circle.setAttribute('cy', point.y);
    // Define a random radius for each circle
    circle.setAttribute('r', `${(Math.random() * 2) + 0.2}`);
    // Define a random color
    circle.setAttribute('fill', gsap.utils.random(['#ff0000', '#ff5a00', '#ff9a00', '#ffce00', '#ffe808']));

    // Animate the circle
    gsap.to(circle, {
      // Random cx based on its current position
      cx: '+=random(-20,20)',
      // Random cy based on its current position
      cy: '+=random(-20,20)',
      // Fade out
      opacity: 0,
      // Random duration for each circle
      duration: 'random(1, 2)',
      // Prevent gsap from rounding the cx & cy values
      autoRound: false,
      // Once the animation is complete
      onComplete: () => {
        // Remove the SVG element from its parent
        svg?.removeChild(circle);
      }
    });
  }

  return {
    fuse
  }
}

import { Button, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useGameContext } from './GameContext';
import { useState } from 'react';

function Welcome() {
  let navigate = useNavigate();
  let { createRoom } = useGameContext();

  let [creatingRoom, setCreatingRoom] = useState(false);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1em',
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{
            cursor: 'pointer',
            fontSize: '4em',
            textTransform: 'none',
          }}
          onClick={() => {
            setCreatingRoom(true);
            createRoom((code) => {
              navigate(`/game/${code}`);
            });
          }}
          disabled={creatingRoom}
        >
          create room
        </Button>
        <Button variant="contained"
          size="large"
          style={{
            cursor: 'pointer',
            fontSize: '4em',
            textTransform: 'none',
          }}
          onClick={() => navigate(`/enter-code`)}
          disabled={creatingRoom}
        >
          join room
        </Button>
      </Box>
    </Box>
  );
}

export default Welcome;

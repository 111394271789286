import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGameContext } from "./GameContext";
import { useState } from "react";

export function EnterNameDialog({open = false, handleClose}: {open?: boolean, handleClose?: () => void}) {
  let {name, changeName} = useGameContext();
  const [nameTextFieldValue, setNameTextFieldValue] = useState<string>(name === "anon" ? "" : name);
  function updateChatName() {
    if (nameTextFieldValue.length === 4 || nameTextFieldValue.length === 6) {
      changeName(nameTextFieldValue);
      handleClose?.();
    }
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>enter your name!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            your name must be 4 or 6 characters long
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Name:"
            type="email"
            fullWidth
            variant="standard"
            value={nameTextFieldValue}
            inputProps={{
              maxLength: 6,
              minLength: 4,
            }}
            required
            onChange={event => setNameTextFieldValue(event.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                updateChatName();
              }
            }}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            disabled={!(nameTextFieldValue.length === 4 || nameTextFieldValue.length === 6)}
            onClick={() => updateChatName()}
          >set name</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import {
  Box,
  createTheme,
  CssBaseline, ThemeProvider, useMediaQuery,
} from '@mui/material';
import React, { useMemo } from 'react';
import Welcome from "./Welcome";
import { Route, Routes } from "react-router-dom";
import { Game } from "./Game";
import { EnterCode } from "./EnterCode";
import { GameContextProvider } from './GameContext';
import { Banner } from "./Banner";
import { darkTheme, lightTheme } from "./theme";
import './App.css';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => createTheme(
    prefersDarkMode ? darkTheme : lightTheme
  ), [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <GameContextProvider>
        <CssBaseline />
        <Banner />
        <Box className={"routesContainer"}>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/game/:code?" element={<Game />} />
              <Route path="/enter-code" element={<EnterCode />} />
            </Routes>
         </Box>
      </GameContextProvider>
    </ThemeProvider>
  );
}

export default App;
